import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, combineLatest, throwError, BehaviorSubject } from 'rxjs';
import { ResponseContentType } from '@angular/http';
import { map, catchError } from 'rxjs/operators';
import { ILogin, ILoginResult, IRegister, IFranchsiePosalCode, ICatagoryWithProduct, IRentalProducts, ICustomer, IRentalProductBookings, ICheckout, IResponse, IDeliverySlots, RentalProductsCatagory, IStandardProductCatagory, IStandardProducts, IKitProducts, ICompany, ICompanyData, UpcomingRenewalsTemp, DeliveryAddress, ResponseClass, ResponseClassWCode, ICustomerEmails, ResetCode, responseCustomerId, responseCustomerVerify, IBannerData, ProModel } from './Interfaces/ILogin';
import { customerOrdersList, SingleOrder, responseMsg, ProductAvalibilityResponse, extendDaysResponse, IQoute, ICustomerBilling, responseId, ITotalQoutation, IContact, ICustomerProfile, IFranchiseProfileList, IResponseClass, ICareerBundle, ICareer, FoundUsOn } from './Interfaces/IInterfaces';
import * as FileSaver from 'file-saver';
import { Ifaq } from './Interfaces/faq';
import { request } from 'http';

@Injectable()
export class DataService {

  private cartSource = new BehaviorSubject<number>(0);
  currentNoInCart = this.cartSource.asObservable();

  changeCartValue(total: number) { 
    this.cartSource.next(total); 
  }

  private cartTotalSource = new BehaviorSubject<number>(0);
  totalPriceCart = this.cartTotalSource.asObservable();

  changeCartTotalValue(total: number) {
    this.cartTotalSource.next(total);
  }

  private cartShowSource = new BehaviorSubject<boolean>(false);
  showCart = this.cartShowSource.asObservable();

  changeShowCartValue(show: boolean) {
    this.cartShowSource.next(show);
  }

  //baseurl: string = 'https://customerwebapi.therentalguys.ca';
  baseurlImg: string = 'https://customerwebapi.therentalguys.ca';
  //baseurlService: string = 'https://mobileapp.therentalguys.ca';
  //baseurl: string = 'https://localhost:44367';
  //baseurl: string = 'https://customer-developer-api.therentalguys.ca';
  baseurl: string = 'https://trg-customerapi-live.therentalguys.ca';
  //baseurl: string = 'https://customer-staging-api-new.therentalguys.ca';
  //baseurlService: string = 'https://localhost:44367';
  //baseurl: string = 'https://mobileapp.therentalguys.ca';
  baseurlService: string = 'https://customerapiservice.therentalguys.ca';
  //baseurl: string = 'https://customerapiservice.therentalguys.ca';
  //baseurl: string = 'https://customerapi.therentalguys.ca';
  baseurlAdmin: string = 'https://customer-developer-api.therentalguys.ca';

  //baseurl: string = 'https://customerapi.containerrentals.ca'; 
  //baseurlAdmin: string = 'https://adminapi.containerrentals.ca';

  videoUrl: string = `${this.baseurl}/ProductVideo/`;

  baseCatagory: string = this.baseurlAdmin + '/CatagoryImages/';

  //baseurlAdmin: string = 'https://localhost:44398';
   
  picturesUrl: string = this.baseurlAdmin + '/productImage/'

  registerBooking: string = '/api/CreateAccountBooking'

  accountUrl: string = '/api/AccountWeb/';

  accountUnloggedInUrl: string = '/api/AccountUnloggedIn/';

  postalCode: string = '/api/PostalCodeCatagories/?code=';

  catagory: string = '/api/CatagoryProductsList/';

  //catagoryWName: string = '/api/CatagoryProductsListWName/';

  rentalproduct: string = '/api/RentalProducts/';

  standardproduct: string = '/api/StandardProducts/';

  kitproduct: string = '/api/Kits/';

  rentalBooking: string = '/api/RentalBooking';

  standardBooking: string = '/api/StandardProductBooking';

  kitBooking: string = '/api/KitProductBooking';

  checkout: string = '/api/Checkout/';

  customerOrders: string = '/api/customerOrders/';

  singleOrder: string = '/api/singleOrder/?id=';

  companyData: string = '/api/CompanyData/';

  companyDataFull: string = '/api/CompanyDataFull';

  CustomerDeliveryAddress: string = '/api/DeliveryAddresses/'

  constructor(private http: HttpClient) { }

  getOrderSignature(code: string): Observable<SingleOrder> {
    let url = this.baseurl + "/api/orderSignature/?code=" + code;
    return this.http.get<SingleOrder>(url).pipe();
  }

  uploadResumeFile(url, uploadData): Observable<any> {
    return this.http.post(url, uploadData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  changePassword(email: string, oldPassword: string, newPassword: string) {
    let url = this.baseurl + `/api/AccountWeb/ChangePassword/${email}/${oldPassword}/${newPassword}`;
    return this.http.get<responseMsg>(url).pipe();
  }

  /*
   * Billing Profile
   */

  currentBilling(CustomerId: number, FranchiseId: number): Observable<ICustomerProfile> {
    let url = this.baseurl + `/api/currentCustomerBilling/?CustomerId=${CustomerId}&FranchiseId=${FranchiseId}`;
    return this.http.get<ICustomerProfile>(url).pipe();
  }

  getBillingProfileList(customerID: number): Observable<IFranchiseProfileList[]> {
    let url = this.baseurl + '/api/BillingProfilesList/?id=' + customerID;
    return this.http.get<IFranchiseProfileList[]>(url).pipe();
  }

  makeDefaultCustomerProfile(id: number, franchiseId: number): Observable<responseMsg> {
    let url = this.baseurl + `/api/MakeDefaultBillingProfile/?Id=${id}&FranchiseId=${franchiseId}`;
    return this.http.get<responseMsg>(url);
  }

  deleteCustomerBilling(Id: number) {
    return this.http.delete<void>(this.baseurl + '/api/CreateBillingProfile/' + Id);
  }

  deleteCustomerEmail(Id: number) {
    return this.http.delete<void>(this.baseurl + '/api/CustomerEmailsCRUD/' + Id);
  }

  /*Start CustomerDeliveryAddress*/

  SingleDeliveryAddresses(id: number): Observable<DeliveryAddress> {
    let url = this.baseurl + '/api/SingleDeliveryAddresses/' + id;
    return this.http.get<DeliveryAddress>(url).pipe();
  }

  selectOrderDeliveryAddress(orderId: number, addressId: number): Observable<ResponseClass> {
    let url = this.baseurl + `/api/changeDeliveryAddressOrder/?OrderId=${orderId}&AddressID=` + addressId;
    return this.http.get<ResponseClass>(url);
  }

  selectDeliveryAddress(addressId: number, customerId: number): Observable<ResponseClass> {
    let url = this.baseurl + `/api/SelectDeliveryAddressCheckout/?CustomerID=${customerId}&AddressID=` + addressId;
    return this.http.get<ResponseClass>(url);
  }

  getCustomerDeliveryAddressList(customerId: number, franchiseId): Observable<DeliveryAddress[]> {
    let url = this.baseurl + '/api/DeliveryAddressListFranchise/' + '?CustomerId=' + customerId + '&FranchiseId=' + franchiseId;
    return this.http.get<DeliveryAddress[]>(url).pipe();
  }

  addCustomerDeliveryAddress(item: DeliveryAddress): Observable<ResponseClassWCode> {
    var url = this.baseurl + this.CustomerDeliveryAddress;
    return this.http.post<ResponseClassWCode>(url, item, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  addCustomerDeliveryAddressOrder(item: DeliveryAddress): Observable<ResponseClassWCode> {
    var url = this.baseurl + this.CustomerDeliveryAddress;
    return this.http.post<ResponseClassWCode>(url, item, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  updateCustomerDeliveryAddress(item: DeliveryAddress): Observable<ResponseClassWCode> {
    var url = this.baseurl + this.CustomerDeliveryAddress + item.addressId;
    return this.http.put<ResponseClassWCode>(url, item, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  deleteCustomerDeliveryAddress(Id: number) {
    return this.http.delete<void>(this.baseurl + this.CustomerDeliveryAddress + Id);
  }

  makeDefaultDeliveryAddress(id: number, franchiseId: number, customerId: number): Observable<responseMsg> {
    let url = this.baseurl + `/api/makeDefaultDelivery/?Id=${id}&FranchiseId=${franchiseId}&CustomerId=` + customerId;
    return this.http.get<responseMsg>(url);
  }

  /*End CustomerDeliveryAddress*/

  getAutoRenewals(customerId): Observable<UpcomingRenewalsTemp[]> {
    let url = this.baseurl + "/api/standardRentalsRenewals/?CustomerId=" + customerId;
    return this.http.get<UpcomingRenewalsTemp[]>(url).pipe();
  }

  cancelPickup(orderId: number): Observable<responseMsg> {
    let url = this.baseurl + "/api/CancelPickup/?OrderID=" + orderId;
    return this.http.get<responseMsg>(url).pipe();
  }

  setLimitedDamage(IsAccepted: boolean, customerId: number): Observable<void> {
    let url = this.baseurl + "/api/setLimitedWavier/?CustomerID=" + customerId + "&Accept=" + IsAccepted;
    return this.http.get<void>(url).pipe();
  }

  contactRequest(contact: IContact): Observable<IContact> {
    let url = this.baseurl + `/api/contactNewRequest/?fullName=${contact.fullname}&location=${contact.location}&phone=${contact.phone}&email=${contact.email}&message=${contact.message}&currentFranchise=${contact.currentFranchise}`;
    return this.http.get<IContact>(url).pipe();
  }

  getCompanyData(fromTerms: Boolean): Observable<ICompanyData> {
    let url = this.baseurl + this.companyDataFull;

    if (fromTerms) {
      url = this.baseurl + this.companyData;
    }

    return this.http.get<ICompanyData>(url).pipe();
  }

  getBannerData(): Observable<IBannerData> {
    //let url = 'http://apptrgapi-dev.us-west-1.elasticbeanstalk.com/api/bannerData';

    let url = this.baseurlService+'/api/bannerData';

    return this.http.get<IBannerData>(url).pipe();
  }

  ///api/proContractorBanner/?CustomerId=&FranchiseId=
  getProContractorBannerData(CustomerId: number, FranchiseId:number): Observable<ProModel> {

    let url = this.baseurlService + `/api/proContractorBanner/?CustomerId=${CustomerId}&FranchiseId=${FranchiseId}`;

    return this.http.get<ProModel>(url).pipe();
  }


  getCurrentCart(comingfrom) {
    let customerId = localStorage.getItem("customerId");
    if (customerId != undefined) {
      this.getItemsInCart(comingfrom)
        .subscribe(
          (data: number) => {
            this.changeCartValue(data);
          },
          (err: any) => console.log(err),
          () => {
          }
        );
    }
  }

  registerCustomerBooking(register: IRegister): Observable<ICustomer> {
    var url = this.baseurl + this.registerBooking;
    return this.http.post<ICustomer>(url, register, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  //api/AccountWeb/ForgotPassword / anadeemus@msn.com

  forgotPassword(email: string, phone, resetWPhone,code:string): Observable<ResetCode> {
    var url = this.baseurl + `/api/AccountWeb/ForgotPasswordPhone/${email}/${phone}/${resetWPhone}/${code}`;

    if (!resetWPhone)
    {
      url = this.baseurl + `/api/AccountWeb/ForgotPassword/${email}/${code}`;
    }

    return this.http.get<ResetCode>(url);
  }

  validateCode(email: string, phone, resetWPhone): Observable<void> {
    var url = this.baseurl + `/api/resetCode/?Email=${email}&Phone=${phone}&hasPhone=${resetWPhone}`;
    return this.http.get<void>(url);
  }

  registerCustomerUnLoggedIn(register: IRegister, customerId: number): Observable<ICustomer> {
    register.tempCustomerId = customerId;
    //var url = encodeURI(this.baseurl
    //  + this.accountUnloggedInUrl + register.email + '/' + escape(register.password) + '/' + register.phone + '/' + register.firstName + '/' + register.lastName + '/' + customerId + '/' + register.foundUsOn);

    ////var encodedUrl = escape(url);
    //return this.http.get<ICustomer>(url);

    var url = this.baseurlService + "/api/AccountRegister";
    return this.http.post<ICustomer>(url, register, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }

  registerCustomer(register: IRegister): Observable<ICustomer> {
    var url = this.baseurl + this.accountUrl + register.email + '/' + escape(register.password) + '/' + register.phone + '/' + register.firstName + '/' + register.lastName + '/' + register.foundUsOn;

    //var encodedUrl = escape(url);
    return this.http.get<ICustomer>(url);
  }

  //api/ItemsInCart/?CustomerID=100276

  getItemsInCart(comingFrom): Observable<number> {

    console.log(comingFrom);

    let customerId = localStorage.getItem("customerId");
    let url = this.baseurl + `/api/ItemsInCart/?CustomerID=${customerId}`;
    return this.http.get<number>(url).pipe();

  }

  getAllPostalCode(postalcode: string): Observable<IFranchsiePosalCode> {
    let url = this.baseurl + this.postalCode + postalcode;
    return this.http.get<IFranchsiePosalCode>(url).pipe();
  }

  //api/updateBrowserToken/?CustomerID=&token=
  updateToken(customerId: number, token: string): Observable<responseMsg> {
    let url = this.baseurl + `/api/updateBrowserToken/?CustomerID=${customerId}&token=${token}`;
    return this.http.get<responseMsg>(url).pipe();
  }

  getCatagoryProductsWNames(catagoryName: string, franchiseName: string): Observable<ICatagoryWithProduct> {
    let url = this.baseurl + `/api/CatagoryProductsListWName/?CatagoryName=${catagoryName}&FranchiseName=${franchiseName}`;
    return this.http.get<ICatagoryWithProduct>(url).pipe();
  }

  getCatagoryProducts(catagoryId: number, franchiseId): Observable<ICatagoryWithProduct> {
    let url = this.baseurl + this.catagory + "?CatagoryID=" + catagoryId + "&FranchiseID=" + franchiseId;
    return this.http.get<ICatagoryWithProduct>(url).pipe();
  }

  getRentalProductsWName(franchise: string, productName: string): Observable<IRentalProducts> {
    let url = this.baseurl + `/api/RentalProductsWName/?Name=${productName}&CurrentFranchise=${franchise}`;;
    return this.http.get<IRentalProducts>(url).pipe();
  }

  getRentalProducts(productId: number): Observable<IRentalProducts> {
    let url = this.baseurl + this.rentalproduct + "?id=" + productId + "&CurrentFranchise=Calgary";
    return this.http.get<IRentalProducts>(url).pipe();
  }

  getStandardProducts(productId: number): Observable<IStandardProducts> {
    let url = this.baseurl + this.standardproduct + "?id=" + productId + "&CurrentFranchise=";
    return this.http.get<IStandardProducts>(url).pipe();
  }

  getKitProducts(productId: number): Observable<IKitProducts> {
    let url = this.baseurl + this.kitproduct + "?id=" + productId + "&CurrentFranchise=";
    return this.http.get<IKitProducts>(url).pipe();
  }

  getUnLoggedCustomer(): Observable<responseCustomerId> {
    let url = this.baseurl + "/api/createTempUser";
    return this.http.get<responseCustomerId>(url).pipe();
  }

  getVerifyCustomer(id:number): Observable<responseCustomerVerify> {
    let url = this.baseurl + "/api/verifyCustomerId/?id="+id;
    return this.http.get<responseCustomerVerify>(url).pipe();
  }

  KitProductBookingUnloggedIn(booking: IRentalProductBookings): Observable<void> {
    var url = this.baseurl + "/api/KitProductBookingUnloggedIn";
    return this.http.post<void>(url, booking, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  
  StandardProductBookingUnloggedIn(booking: IRentalProductBookings): Observable<void> {
    var url = this.baseurl + "/api/StandardProductBookingUnloggedIn";
    return this.http.post<void>(url, booking, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  bookRentalProductUnloggedIn(booking: IRentalProductBookings): Observable<void> {
    var url = this.baseurl + "/api/RenalBookingUnloggedIn";
    return this.http.post<void>(url, booking, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  bookRentalProduct(booking: IRentalProductBookings): Observable<void> {
    var url = this.baseurl + this.rentalBooking;
    return this.http.post<void>(url, booking, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  bookStandardRentalProduct(booking: IRentalProductBookings): Observable<void> {
    var url = this.baseurl + "/api/StandardRentalBooking";
    return this.http.post<void>(url, booking, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  bookStandardProduct(booking: IRentalProductBookings): Observable<void> {
    var url = this.baseurl + this.standardBooking;
    return this.http.post<void>(url, booking, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  bookKitProduct(booking: IRentalProductBookings): Observable<void> {
    var url = this.baseurl + this.kitBooking;
    return this.http.post<void>(url, booking, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getCheckout(customerId: number): Observable<ICheckout> {
    let url = this.baseurl + this.checkout + "?id=" + + customerId;
    console.log(url);
    return this.http.get<ICheckout>(url).pipe();
  }

  setDockDelivery(IsDock: boolean, customerId: number): Observable<void> {
    let url = this.baseurl + "/api/HasDockDelivery/?IsdockDelivery=" + IsDock + "&CustomerID=" + + customerId;
    return this.http.get<void>(url).pipe();
  }

  setDeliverySlot(Id: number, orderId: number): Observable<void> {
    let url = this.baseurl + "/api/SetDeliverySlots/?id=" + Id + "&OrderID=" + orderId;
    return this.http.get<void>(url).pipe();
  }

  //api/PlaceOrder/?CustomerID=

  foundUsOnCheckout(customerID: number,foundUsOn): Observable<void> {
    let url = this.baseurl + "/api/foundUsOnCheckout/?CustomerID=" + customerID + "&FoundUsOn=" + foundUsOn;
    return this.http.get<void>(url).pipe();
  }

  placeOrder(customerID: number): Observable<responseMsg> {
    let url = this.baseurl + "/api/PlaceOrderWeb/?CustomerID=" + customerID;
    return this.http.get<responseMsg>(url).pipe();
  }

  removeFromCart(Id: number): Observable<IResponse> {
    let url = this.baseurl + "/api/RemoveCartRes/?OrderDetailsID=" + Id;
    return this.http.get<IResponse>(url).pipe();
  }

  //api/UpdateDeliveryAddressAndNotes/?CustomerID=&DeliveryAddress=&PostalCode=&OrderNotes=&siteContact=&UnitNo=
  updateNotesAddress(notes: string, customerId: number): Observable<IFranchsiePosalCode> {
    let url = this.baseurl + "/api/UpdateDeliveryAddressAndNotes/?CustomerID="
      + customerId + "&OrderNotes=" + notes;
    return this.http.get<IFranchsiePosalCode>(url).pipe();
  }

  getCustomerOrders(customerID: number): Observable<customerOrdersList> {
    let url = this.baseurl + this.customerOrders + customerID;
    return this.http.get<customerOrdersList>(url).pipe();
  }

  getSingleOrder(orderId: number, customerId: number): Observable<SingleOrder> {
    let url = this.baseurl + this.singleOrder + orderId + '&CustomerId=' + customerId;
    return this.http.get<SingleOrder>(url).pipe();
  }

  getCareer(): Observable<ICareerBundle> {
    let url = this.baseurl + '/api/careerList';
    return this.http.get<ICareerBundle>(url).pipe();
  }

  //https://customerapi.therentalguys.ca/api/FoundUsOns

  foundUsOn(): Observable<FoundUsOn[]> {
    let url = this.baseurl + '/api/FoundUsOns';
    return this.http.get<FoundUsOn[]>(url).pipe();
  }

  getCareerDetails(careerId: number): Observable<ICareer> {
    let url = this.baseurl + '/api/careerDetails/?CareerId=' + careerId;
    return this.http.get<ICareer>(url).pipe();
  }


  dockDelivery(orderId: number, checked: boolean): Observable<responseMsg> {
    let url = this.baseurl + `/api/IsDockDelivery/?OrderId=${orderId}&checkstate=${checked}`;
    return this.http.get<responseMsg>(url).pipe();
  }

  sameDayPickup(orderId: number, checked: boolean): Observable<responseMsg> {
    let url = this.baseurl + `/api/samedayPickup/?OrderDetailId=${orderId}&checkstate=${checked}`;
    return this.http.get<responseMsg>(url).pipe();
  }

  updateDelivery(orderId: number, deliveryAddress: string, postalCode: string, OrderNotes: string, siteContact: string): Observable<responseMsg> {
    let url = this.baseurl + `/api/changeDeliveryInfo/?OrderID=${orderId}&DeliveryAddress=${deliveryAddress}&postalCode=${postalCode}&OrderNotes=${OrderNotes}&SiteContactNumber=${siteContact}`;
    return this.http.get<responseMsg>(url).pipe();
  }

  completeStandardRental(orderId: number, newDeliveryDate: string): Observable<responseMsg> {
    let url = this.baseurl + `/api/CompleteStandardRental/?OrderID=${orderId}&NewDate=${newDeliveryDate}`;
    return this.http.get<responseMsg>(url).pipe();
  }

  updateDeliveryDate(orderId: number, newDeliveryDate: string): Observable<responseMsg> {
    let url = this.baseurl + `/api/changeDeliveryDate/?OrderID=${orderId}&NewDate=${newDeliveryDate}`;
    return this.http.get<responseMsg>(url).pipe();
  }

  avbPriorityDelivery(orderId: number): Observable<IDeliverySlots[]> {
    let url = this.baseurl + `/api/avbPriorityDelivery/?OrderId=${orderId}`;
    return this.http.get<IDeliverySlots[]>(url).pipe();
  }

  ///api/priorityDelivery/?OrderID=128650&SlotID=100018&ChargeAmount=2&NewDeliveryAmount=5  
  updateDeliveryTime(orderId: number, slotID: number, chargeAmount: number, newDeliveryAmount: number): Observable<responseMsg> {
    let url = this.baseurl + `/api/priorityDelivery/?OrderID=${orderId}&SlotID=${slotID}&ChargeAmount=${chargeAmount}&NewDeliveryAmount=${newDeliveryAmount}`;
    return this.http.get<responseMsg>(url).pipe();
  }

  allRentalProductList(franchiseId: number): Observable<RentalProductsCatagory[]> {
    let url = this.baseurl + `/api/ProductsList/?FranchiseID=${franchiseId}`;
    return this.http.get<RentalProductsCatagory[]>(url).pipe();
  }

  checkAvalibility(franchiseId: number, productId: number, days: number): Observable<ProductAvalibilityResponse> {
    let url = this.baseurl + `/api/checkAvalibility/?OrderID=${franchiseId}&ProductID=${productId}&TotalDays=${days}`;
    return this.http.get<ProductAvalibilityResponse>(url).pipe();
  }

  addProductToOrder(franchiseId: number, productId: number, days: number): Observable<responseMsg> {
    let url = this.baseurl + `/api/addProductToOrder/?OrderID=${franchiseId}&ProductID=${productId}&TotalDays=${days}`;
    return this.http.get<responseMsg>(url).pipe();
  }


  extendAbleDaysCount(orderDetailID: number): Observable<extendDaysResponse> {
    let url = this.baseurl + `/api/extend/?OrderDetailsID=${orderDetailID}`;
    return this.http.get<extendDaysResponse>(url).pipe();
  }

  extendOrder(orderDetailID: number, extendDays: number, prodType: string): Observable<responseMsg> {
    let url = this.baseurl;

    if (prodType == "RP") {
      url = url + `/api/chargeExtendOrder/?OrderDetailID=${orderDetailID}&CurrentSerial=1234&ExtendedDays=${extendDays}`;
    }
    else if (prodType == "KP") {
      url = url + `/api/chargeExtendKit/?OrderDetailID=${orderDetailID}&ExtendedDays=${extendDays}`;
    }

    return this.http.get<responseMsg>(url).pipe();
  }

  //api/chargeExtendKit/?OrderDetailID=41794&ExtendedDays=2
  ///api/chargeExtendOrder/?OrderDetailID=41790&CurrentSerial=100617&ExtendedDays=2


  //https://localhost:44367/api/relocationCostDetails/?OrderId=128714
  relocationCostDetails(orderId: number): Observable<IStandardProductCatagory> {
    let url = this.baseurl + `/api/relocationCostDetails/?orderId=${orderId}`;
    return this.http.get<IStandardProductCatagory>(url).pipe();
  }

  ///api/RelocationOrder/?OrderId=128714&newDeliveryAddress=76 covepark green NE&newPostalCode=t3k6l2&relocationDate=2019-12-31
  relocationProduct(orderId: number, address: string, postalCode: string, relocationDate: Date): Observable<IResponse> {

    let rDate = `${relocationDate.getFullYear()}-${(relocationDate.getMonth() + 1)}-${relocationDate.getDate()}`;

    let url = this.baseurl + `/api/RelocationOrder/?OrderId=${orderId}&newDeliveryAddress=${address}&newPostalCode=${postalCode}&relocationDate=${rDate}`;
    return this.http.get<IResponse>(url).pipe();
  }

  downloadPDF(Id: number) {

    let url = this.baseurl + '/api/downloadInvoice/?orderId=' + Id

    let fileName: string = `Invoice Order No: ${Id}`;

    this.http.get(url, { responseType: 'blob' })
      .subscribe((file: Blob) => {
        FileSaver.saveAs(file, fileName);
      });

  }


  getCustomerQoutations(customerID: number): Observable<IQoute[]> {
    let url = this.baseurl + '/api/QouteList/?CustomerID=' + customerID;
    return this.http.get<IQoute[]>(url).pipe();
  }

  getCustomer(customerID: number): Observable<ICustomer> {
    let url = this.baseurl + '/api/Customers/' + customerID;
    return this.http.get<ICustomer>(url).pipe();
  }


  updateCustomer(customer: ICustomer): Observable<responseMsg> {
    let url = this.baseurl
      + `/api/updateCustomer/?CustomerID=${customer.customerId}&FirstName=${customer.firstName}&LastName=${customer.lastName}&CompanyName=${customer.companyNmae}&Phone=${customer.phone}&mobileNo1=${customer.mobileNo1}&mobileNo2=${customer.mobileNo2}&FoundUsOn=${customer.foundUsOn}`;
    return this.http.get<responseMsg>(url).pipe();
  }


  addAdditionalEmail(email: ICustomerEmails): Observable<void> {
    var url = this.baseurl + `/api/CustomerEmailsCRUD`;
    return this.http.post<void>(url, email, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  updateBilling(billing: ICustomerBilling): Observable<IResponseClass> {
    var url = this.baseurl + `/api/CreateBillingProfile`;
    return this.http.post<IResponseClass>(url, billing, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  //api/createQouation/?CustomerID=102598&FranchiseId=
  startQoute(customerId: number, FranchiseId: number) {
    let url = this.baseurl
      + `/api/createQouation/?CustomerID=${customerId}&FranchiseId=${FranchiseId}`;
    return this.http.get<responseId>(url).pipe();
  }

  getQouteDetails(qouteId: number): Observable<ITotalQoutation> {
    let url = this.baseurl + '/api/singleQoutation/?qoutationID=' + qouteId;
    return this.http.get<ITotalQoutation>(url).pipe();
  }
  //ProductID,string ProductType,long QouteID,int TotalDay,int quantity
  addProductToQoute(orderId: number, productId: number, totalDays: number, quantity: number, type: string): Observable<void> {
    let url = this.baseurl + `/api/addProductToQoutation/?productId=${productId}&ProductType=${type}&QouteID=${orderId}&TotalDay=${totalDays}&quantity=${quantity}`;
    return this.http.get<void>(url).pipe();
  }

  getCostProduct(quantity: number, productId: number, days: number): Observable<ProductAvalibilityResponse> {
    let url = this.baseurl + `/api/productCost/?Quantity=${quantity}&ProductID=${productId}&TotalDays=${days}`;
    return this.http.get<ProductAvalibilityResponse>(url).pipe();
  }

  removeProd(Id: number): Observable<responseMsg> {
    let url = this.baseurl + "/api/removeProdFromQoute/?detailsId=" + Id;
    return this.http.get<responseMsg>(url).pipe();
  }

  //https://localhost:44367/Home/QouteInvoice/?OrderID=10217

  downloadQouteInvoice(Id: number) {

    let url = this.baseurl + '/api/qoutationPdf/?orderId=' + Id

    let fileName: string = `Qoute No: ${Id}`;

    this.http.get(url, { responseType: 'blob' })
      .subscribe((file: Blob) => {
        FileSaver.saveAs(file, fileName);
      });
  }

  updateDeliveryQoute(orderId: number, deliveryAddress: string, postalCode: string, OrderNotes: string): Observable<responseMsg> {
    let url = this.baseurl + `/api/qouteDeliveryInfo/?OrderID=${orderId}&DeliveryAddress=${deliveryAddress}&postalCode=${postalCode}&OrderNotes=${OrderNotes}`;
    return this.http.get<responseMsg>(url).pipe();
  }

  updatelimtedQoute(orderId: number, flag: boolean): Observable<responseMsg> {
    let url = this.baseurl + `/api/updatelimtedQoute/?OrderID=${orderId}&flag=${flag}`;
    return this.http.get<responseMsg>(url).pipe();
  }
  Getfaq(): Observable<Ifaq[]> {
    return this.http.get<Ifaq[]>(this.baseurlAdmin+'/api/FAQs')
}
}

