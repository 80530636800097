import { Component, OnInit, ViewChild, ElementRef, Renderer2, PLATFORM_ID, Inject, AfterViewInit, HostListener } from '@angular/core';
import { DataService } from '../shared/data.service';
import { ICheckout, IDeliverySlots, IDeliverybyOrder, IResponse, IFranchsiePosalCode, IRentalProducts, IKitProducts, IStandardProducts, ICompany, ICompanyData } from '../shared/Interfaces/ILogin';
import { interval, Subscription } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators'
import { Router } from '@angular/router';
import { MatSnackBar, MatDialog } from '@angular/material';
//import { Address } from 'cluster';
import { Address as gAddress, Address } from "ngx-google-places-autocomplete/objects/address";
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { FormGroup, FormControl } from '@angular/forms';
import { responseMsg, FoundUsOn } from '../shared/Interfaces/IInterfaces';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SignDialogComponent } from './sign-dialog/sign-dialog.component';
import { Title } from '@angular/platform-browser';
import { SelectDeliveryAddressDialogComponent } from './select-delivery-address-dialog/select-delivery-address-dialog.component';
import { EditDeliveryAddressComponent } from '../my-dashboard/delivery-address-list/edit-delivery-address/edit-delivery-address.component';
import { AddDeliveryAddressComponent } from '../my-dashboard/delivery-address-list/add-delivery-address/add-delivery-address.component';
import { isPlatformBrowser } from '@angular/common';
import { DeclineLWDDialogComponent } from './decline-lwddialog/decline-lwddialog.component';
import { HelperService } from '../shared/Helper.service';

declare var $: any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit, AfterViewInit {

  constructor(@Inject(PLATFORM_ID) private _platformId: Object, private titleService: Title, private ngxUiLoaderService: NgxUiLoaderService, private renderer: Renderer2, private _snackBar: MatSnackBar,
    private router: Router, private dataService: DataService, public dialog: MatDialog, private helperService: HelperService) {
    this.titleService.setTitle("TheRentalGuys.Ca - Checkout");
    dataService.getCurrentCart('checkout');
  }

  subscription = new Subscription;
  screenWidth: number;
  
  @ViewChild("placesRef", { static: true }) placesRef: GooglePlaceDirective;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = event.target.innerWidth;
  }
  
  public handleAddressChange(address: Address) {
    //this.postalCode = address.(address, "postal_code");
    console.log(address);

    for (let component of address.address_components) {
      for (let type of component.types) {
        if (type == "postal_code") {
          this.postalCode = component.long_name;
        }
      }
    }
    this.deliveryAddress = address.formatted_address;

    this.updateAddress();
  }

  updateAddress(): void {

    if (this.notes != null && this.notes != undefined) {
      this.dataService.updateNotesAddress(this.notes, this.customerId)
        .subscribe(
          (data: IFranchsiePosalCode) => {
          },
          (err: any) => {
            this.IsDeliveryAddressEntered = true;
            this.IsValidDeliveryAddress = false;
          },
          () => {
            console.log("delivery options selected");
          }
        );
    }

  }

  franchiseId = 0;

  deliveryAddress: string;
  postalCode: string = "";
  siteContact: string = "";
  notes: string = "";
  unitNo: string = "";

  customerId;
  IsPageLoaded = false;
  EmptyCart = false;
  checkout: ICheckout = new ICheckout();

  isSigned = false;

  deliverySlotsSelected: IDeliverybyOrder[] = [];

  minutesLeft = "30";
  secondsLeft = "00";

  zero = "0";

  pictureUrl: string = "";

  TotalProductCost = 0;
  DeliveryCharge = 0;
  DeliverySlotCost = 0;
  TaxclassTxt = "";
  TotalTax = 0;
  TotalCost = 0;
  subTotal = 0;
  ProContractorDiscount = 0;

  IsDeliveryOptionSelected = false;
  IsDeliveryAddressEntered = false;
  IsDeliverySlotSelected = false;
  IsSigned = false;
  IsAgreementChecked = true;
  IsValidDeliveryAddress = false;
  IsLimitedOptionSelected = false;
  IsLimitedAccepetd = false;
  FoundUsOnSelected = false;

  isAlive = true;

  accept: string = 'Accept'
  decline: string = 'Decline'

  @ViewChild('deliveryInfoDiv') deliveryInfoDiv: any;
  @ViewChild('deliveryGroundDiv') deliveryGroundDiv: any;
  @ViewChild('deliveryDockDiv') deliveryDockDiv: any;
  @ViewChild('agreementDiv') agreementDiv: any;
  @ViewChild('deliverySlotDiv') deliverySlotDiv: any;
  @ViewChild('signatureSlotDiv') signatureSlotDiv: any;
  @ViewChild('deliveryAddressDiv') deliveryAddressDiv: any;

  deliveryInfo = new FormGroup({
    deliveryAddress: new FormControl(''),
    postalCode: new FormControl(''),
    siteContact: new FormControl(''),
    notes: new FormControl(''),
    unitNo: new FormControl('')
  });
  checkNgOnInit: boolean = true;
  totalLimitedDamage = 0;

  totalInitLimitedDamage = 0;

  company: ICompany;

  productImageUrl;
  numWithoutDashes;
  foundUsOn: FoundUsOn[] = [];
  public showDeliveryAddress: string = "false";
  public isFirstLoad: boolean = false;
  public cameFromCart: boolean = false;
  public pageRefreshed: boolean= true;
  public deliveryAddressStatus: string = "";

  ngOnInit() {
    this.screenWidth = window.innerWidth;
    if(this.pageRefreshed)
    {
      this.cameFromCart = true;
    }
    this.helperService.changecheckoutValue.subscribe(()=>{
      this.checkout = this.helperService.checkoutDetails;
      try {
        this.DeliveryCharge = Number(this.checkout.data[0].deliveryCharges) * this.checkout.data.length;
        this.calculateCost();
      } catch (e) {
      }
    });
    this.deliveryAddressStatus = localStorage.getItem("deliveryAddress_status");
    this.helperService.changeDeliveryAddress.subscribe(()=>{
      this.cameFromCart = true;
      this.deliveryAddressStatus = localStorage.getItem("deliveryAddress_status");
      this.showDeliveryAddress = localStorage.getItem("order_deliveryAddress");
      this.DeliveryCharge = 0;
      this.calculateCost();
    });

    try {
      this.dataService.foundUsOn()
        .subscribe(
          (data: FoundUsOn[]) => {
            this.ngxUiLoaderService.stop();
            this.foundUsOn = data;
          },
          (err: any) => console.log(err),
          () => {
            this.ngxUiLoaderService.stop();
            console.log("Data Recived");
          }
        );
    } catch (e) {

    }

    var RefreshRequired = localStorage.getItem("RefreshRequired");

    if (RefreshRequired == "Yes") {
      localStorage.removeItem("RefreshRequired");
      location.reload();
    }

    this.titleService.setTitle("Thank You Proceed to checkout");

    this.customerId = Number(localStorage.getItem("customerId"));
    var retrievedObject = localStorage.getItem('company');
    if (retrievedObject != undefined && retrievedObject != null) {
      this.company = JSON.parse(retrievedObject);
      try {
        this.numWithoutDashes = this.company.helplineNo.replace('(', '').replace(')', '').replace('-', '').replace('-', '').replace('-', '')
      } catch (e) {

      }

    }
    else {
      this.getCompany();
    }

    try {
      if (localStorage.getItem('deliveryAddress') != "null") {
        this.deliveryAddress = localStorage.getItem('deliveryAddress');
      }
      //this.notes = localStorage.getItem('notes');
      //this.postalCode=localStorage.getItem('postalCode');
      //this.siteContact = localStorage.getItem('siteContact');

      if (localStorage.getItem('postalCode') != 'null') {
        this.postalCode = localStorage.getItem('postalCode');
      }

      if (localStorage.getItem('siteContact') != 'null') {
        this.siteContact = localStorage.getItem('siteContact');
      }

      if (localStorage.getItem('notes') != 'null') {
        this.notes = localStorage.getItem('notes');
      }

      if (localStorage.getItem('unitNo') != 'null') {
        this.unitNo = localStorage.getItem('unitNo');
      }

      if (this.deliveryInfo.valid) {
        this.updateAddress();
      }
    } catch (e) {
    }

    try {
      localStorage.removeItem("comingFromInvoiceSignature");
      localStorage.removeItem("InvoiceSignatureOrderID");
    } catch (e) { }

    if (isPlatformBrowser(this._platformId)) {
      $(document).ready(function () {
        $('canvas').delay(1000).css({ 'background': 'white' });
      });
    }

    this.deliveryInfo.valueChanges.pipe(
      debounceTime(5000)
    ).subscribe((value) => {
      //if (this.deliveryInfo.valid) {

      this.updateAddress();

      //}
    });

    this.pictureUrl = this.dataService.picturesUrl;
    this.getcheckout(this.isFirstLoad);
    
    // localStorage.setItem('order_deliveryAddress', 'false');
    this.showDeliveryAddress = localStorage.getItem("order_deliveryAddress");
    this.checkNgOnInit = false;
    
  }

  ngAfterViewInit(): void {
    this.isFirstLoad= false;
    
    
  }
  getcheckout(firstLoad: boolean = false): void {
    if (isPlatformBrowser(this._platformId)) {
      this.ngxUiLoaderService.start();
      this.dataService.getCheckout(this.customerId)
        .subscribe(
          (data: ICheckout) => {
            this.ngxUiLoaderService.stop();
            if (data != null) {

              try {
                if (data.customer.mobileNo1 == "" || data.customer.mobileNo1 == undefined) {
                  this.router.navigate(["/addmobile"]);
                }
              } catch (e) {

              }

              this.EmptyCart = false;
              this.checkout = data;
              this.franchiseId = data.data[0].franchiseId;
              this.initDeliverySlots();

              this.IsPageLoaded = true;

              try {
                if(this.deliveryAddressStatus == "true")
                {
                  this.DeliveryCharge = 0;
                }
                else{
                  this.DeliveryCharge = Number(data.data[0].deliveryCharges) * data.data.length;
                }
                
              } catch (e) {

              }

              this.calculateCost();

              this.startTimmer();

              localStorage.setItem('checkout', JSON.stringify(this.checkout));

              if (data.profileExists != true) {
                localStorage.setItem('comingFromCheckout', 'yes');
                this.router.navigate(["/billing"]);
              }
              else {
                if (!data.hasDefaultAddress) {
                  this.router.navigate(["/addDeliveryAddress", this.franchiseId]);
                }

                if (data.franchise.hasLimitedDamage) {
                  try {
                    for (var orders of data.data) {
                      for (var product of orders.order) {
                        if (product.productType == "RP") {
                          try {
                            if (product.rentalProduct.limitedPercentage == null || product.rentalProduct.limitedPercentage == undefined) {
                              product.rentalProduct.limitedPercentage = data.franchise.limitedPercentage;
                            }
                          } catch (e) {

                          }

                          try {
                            this.totalLimitedDamage = this.totalLimitedDamage + ((Number(product.totalPrice) * product.rentalProduct.limitedPercentage) / 100);
                          } catch (e) {

                          }
                        }
                        else if (product.productType == "KP") {
                          try {
                            this.totalLimitedDamage = this.totalLimitedDamage + ((Number(product.totalPrice) * Number(data.franchise.limitedPercentage)) / 100);
                          } catch (e) {

                          }
                        }
                      }
                    }

                    this.totalInitLimitedDamage = this.totalLimitedDamage;

                    if (this.totalInitLimitedDamage > 0) {
                      this.acceptLimitedDamage(1);
                    }
                  } catch (e) {

                  }
                }

                try {
                  this.calculateCost();
                } catch (e) {

                }
              }
            }
            else {
              this.EmptyCart = true;
              this.IsPageLoaded = false;
              try {
                this.dataService.changeCartValue(0);
              } catch (e) {

              }
            }
          },
          (err: any) => {
            console.log(err);
            this.ngxUiLoaderService.stop();
            this.EmptyCart = true;
          },
          () => {
            console.log("checkout Data Recived");
          }
        );
    }
    this.cameFromCart = false;
    this.pageRefreshed= false;
  }

  changeDeliveryAddress(): void {
    
    const dialogRef = this.dialog.open(SelectDeliveryAddressDialogComponent, {
      height: '650px',
      width: '1000px',
      disableClose: true,
      data:
      {
        customerId: this.customerId,
        franchiseId: this.franchiseId,
        addressId: this.checkout.deliveryAddress.addressId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.showDeliveryAddress = localStorage.getItem('order_deliveryAddress');
      
      // this.getcheckout();
      // let message = "Changed Successfully";
      // let action = "Ok";
      // this._snackBar.open(message, action, {
      //   duration: 20000,
      // });

    });
    // this.checkout = this.helperService.checkoutDetails;
  }

  editDeliveryAddress(): void {
    const dialogRef = this.dialog.open(EditDeliveryAddressComponent, {
      height: '775px',
      width: '510px',
      disableClose: true,
      data: {
        address: this.checkout.deliveryAddress
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result) {
        this.getcheckout();
        let message = "Update Successfully";
        let action = "Ok";
        this._snackBar.open(message, action, {
          duration: 20000,
        });
      }
    });
  }

  addDeliveryAddress(): void {
    const dialogRef = this.dialog.open(AddDeliveryAddressComponent, {
      height: '850px',
      width: '510px',
      disableClose: true,
      data:
      {
        customerId: this.customerId,
        franchsieId: this.franchiseId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result) {

        this.getcheckout();

        let message = "Added Successfully";
        let action = "Ok";
        this._snackBar.open(message, action, {
          duration: 20000,
        });
      } else {
        this.getcheckout();
      }
    });
  }

  getCompany() {
    localStorage.removeItem('company');
    this.dataService.getCompanyData(false)
      .subscribe(
        (data: ICompanyData) => {
          this.productImageUrl = "";
          this.productImageUrl = data.company.adminApiURL + "/productImage/";
          this.numWithoutDashes = data.company.helplineNo.replace('(', '').replace(')', '').replace('-', '').replace('-', '').replace('-', '')
          localStorage.setItem('company', JSON.stringify(data));
          this.company = data.company;
        },
        (err: any) => console.log(err),
        () => {
          console.log("");
        }
      );
  }

  agreementCheck(): void {
    this.IsAgreementChecked = true;
  }

  checkBox(): void {
    this.IsDeliveryOptionSelected = true;
  }

  deliveryType(id): void {
    this.IsDeliveryOptionSelected = true;
    let IsDock = false;
    if (id == 1) {
      IsDock = true;
    }

    this.dataService.setDockDelivery(IsDock, this.customerId)
      .subscribe(
        (data: void) => {
        },
        (err: any) => {
        },
        () => {
          console.log("delivery options selected");
        }
      );
  }

  acceptLimitedDamage(id): void {
    this.IsLimitedOptionSelected = true;

    if (id == 1) {
      this.IsLimitedAccepetd = true;
    } else {
      this.IsLimitedAccepetd = false;
    }

    this.dataService.setLimitedDamage(this.IsLimitedAccepetd, this.customerId)
      .subscribe(
        (data: void) => {

          let message = "";

          if (this.IsLimitedAccepetd) {
            message = "Limited Damage Waiver Accepted";
            this.accept = "Accepted";
            this.decline = "Decline";
          }
          else {
            message = "Limited Damage Waiver Declined";
            this.accept = "Accept";
            this.decline = "Declined";
          }

          this.calculateCost();

          //let action = "Ok";
          //this._snackBar.open(message, action, {
          //  duration: 10000, horizontalPosition: "center", verticalPosition: "top"
          //});
        },
        (err: any) => {
        },
        () => {
          console.log("set Limited Damage Waiver");
        }
      );
  }

  initDeliverySlots(): void {
    for (let item of this.checkout.data) {
      let temp = new IDeliverybyOrder();
      temp.orderId = item.orderId;
      temp.deliverySlotId = 0;
      temp.slotCost = 0;
      this.deliverySlotsSelected.push(temp);
    }
  }

  calculateCost(): void {
    try {
      var totalProduct = 0;

      for (let item of this.checkout.data) {
        totalProduct = totalProduct + Number(item.totalCost);
      }

      try {
        this.totalLimitedDamage = 0;
        if (this.IsLimitedAccepetd) {
          for (var orders of this.checkout.data) {
            for (var product of orders.order) {
              if (product.productType == 'RP') {
                this.totalLimitedDamage = this.totalLimitedDamage + (Number(product.totalPrice) * product.rentalProduct.limitedPercentage) / 100
              }
              if (product.productType == 'KP') {
                this.totalLimitedDamage = this.totalLimitedDamage + (Number(product.totalPrice) * product.kitsProduct.limitedPercentage) / 100
              }
            }
          }
        }
      } catch (e) {

      }

      this.TotalProductCost = totalProduct;
      let tax = Number(this.checkout.franchiseTax.tax);
      this.TaxclassTxt = this.checkout.franchiseTax.name + "(" + tax + "%)";
      this.subTotal = this.TotalProductCost + this.DeliverySlotCost + this.DeliveryCharge + this.totalLimitedDamage;

      try {
        if (this.checkout.proModel.isEnabledForFranchise) {
          if (this.checkout.proModel.discountPercentage != 0) {
            this.ProContractorDiscount = (totalProduct * this.checkout.proModel.discountPercentage / 100);
            this.TotalProductCost = totalProduct - this.ProContractorDiscount;
            let tax = Number(this.checkout.franchiseTax.tax);
            this.TaxclassTxt = this.checkout.franchiseTax.name + "(" + tax + "%)";
            this.subTotal = this.TotalProductCost + this.DeliverySlotCost + this.DeliveryCharge + this.totalLimitedDamage;
            //this.subTotal = this.subTotal;
          }
        }
      } catch (e) {

      }

      this.TotalTax = this.subTotal * tax / 100;
      this.TotalCost = this.subTotal + this.TotalTax;

    } catch (e) {

    }
  }

  delete_item(id): void {
    this.dataService.removeFromCart(id)
      .subscribe(
        (data: IResponse) => {
          for (let item of this.checkout.data) {
            let index = item.order.findIndex(p => p.orderDetailId == id);
            if (index >= 0) {
              item.order.splice(index, 1);

              if (item.order.length == 0) {
                let orderIndex = this.checkout.data.findIndex(p => p.orderId == item.orderId);
                if (orderIndex >= 0) {
                  this.checkout.data.splice(orderIndex, 1);
                }
              }
            }
          }
          location.reload();
          if (this.checkout.data.length == 0) {
            this.router.navigate(["/"]);
          }
          else {
            this.calculateCost();
          }
        },
        (err: any) => {
        },
        () => {
          console.log("delivery options selected");
        }
      );
  }

  checkDeliverySlot(slotId: number, charge: string, orderId: number): void {
    this.IsDeliverySlotSelected = true;
    let index = this.deliverySlotsSelected.findIndex(p => p.orderId == orderId);
    this.deliverySlotsSelected[index].deliverySlotId = slotId;
    this.deliverySlotsSelected[index].slotCost = Number(charge);
    this.dataService.setDeliverySlot(slotId, orderId)
      .subscribe(
        (data: void) => { },
        (err: any) => { },
        () => {
          console.log("delivery options selected");
        }
      );
    this.calculateDeliverySlots();
  }

  calculateDeliverySlots(): void {
    let cost = 0;
    for (let item of this.deliverySlotsSelected) {
      cost = cost + item.slotCost;
    }
    this.DeliverySlotCost = cost;

    this.calculateCost();
  }

  ngOnDestroy() {
    clearInterval(this.timmerInterval);
    this.isAlive = false;
  }

  timmerInterval;

  startTimmer(): void {

    let miliseconds = this.checkout.miliSecondsLeft;

    //if (this.checkout.minutesLeft != 0) {
    //  miliseconds = this.checkout.minutesLeft * 60000;
    //}
    //else if (this.checkout.secondsLeft > 0)
    //{
    //  miliseconds = this.checkout.secondsLeft * 1000;
    //}

    this.timmerInterval = setInterval(() => {
      //let bookingDate = this.checkout.bookingDate;

      let minutes = 30;
      //let book = new Date(bookingDate).getTime() + minutes * 60000;

      //var now = new Date();
      //var now_utc = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
      //var nowMS = now_utc.getTime();
      //(book - nowMS);
      //this.minutesLeft = Math.round(((miliseconds) / (1000 * 60)));

      miliseconds = miliseconds - 1000;

      var _second = 1000;
      var _minute = _second * 60;
      var _hour = _minute * 60;
      //var _day = _hour * 24;

      //var days = Math.floor(miliseconds / _day);
      //var _hours = Math.floor((miliseconds % _day) / _hour);
      var _minutes = Math.floor((miliseconds % _hour) / _minute);
      var _seconds = Math.floor((miliseconds % _minute) / _second);

      this.minutesLeft = this.aZero(_minutes);
      this.secondsLeft = this.aZero(_seconds);


      if (miliseconds < 0) {
        if (this.isAlive) {
          clearInterval(this.timmerInterval);
          //this.getcheckout()
          let message = "Sorry you are timed out, please add product to cart again, Thankyou";
          let action = "Ok";
          this._snackBar.open(message, action, {
            duration: 2000,
          });
          this.EmptyCart = true;
          //this.getcheckout();
          //this.router.navigate(["/"]);
          try {
            //alert(message);
            //this.router.navigate(["/home"]);
            //location.reload();
          } catch (e) {
          }
        }
        else {
          //this.dataService.getCurrentCart('checkoutTimer');
        }
      }
    }, 1000);
  }

  aZero(n): string {
    return n.toString().length == 1 ? n = '0' + n : n;
  }

  points = [];

  validation(): boolean {
    let IsValid = true;

    let message = "";

    try {
      this.IsSigned = Boolean(localStorage.getItem("IsSigned"));
    } catch (e) {

    }

    if (!this.IsDeliveryOptionSelected) {
      IsValid = false;
      message = "Please select Delivery Option";

      try {
        this.deliveryDockDiv.nativeElement.focus();
      } catch (e) {

      }

      try {
        this.deliveryGroundDiv.nativeElement.focus();
      } catch (e) {

      }
    }
    else if (this.showDeliveryAddress != 'true')
    {
      IsValid = false;
      message = "Please select Delivery Address";

      try {
        this.deliveryAddressDiv.nativeElement.focus();
      } catch (e) {

      }
    }
    //else if (!this.IsValidDeliveryAddress) {
    //  this.renderer.invokeElementMethod(this.deliveryInfoDiv.nativeElement, 'focus');
    //  IsValid = false;
    //  message = "Please enter valid Postal Code";
    //}
    //else if (!this.IsDeliveryAddressEntered) {
    //  this.renderer.invokeElementMethod(this.deliveryInfoDiv.nativeElement, 'focus');
    //  IsValid = false;
    //  message = "Please enter Delivery Address";
    //}
    else if (!this.IsDeliverySlotSelected) {
      IsValid = false;
      message = "Please select Delivery Slot";
      this.deliverySlotDiv.nativeElement.focus();
    }
    else if (!this.IsAgreementChecked) {
      this.agreementDiv.nativeElement.focus();
      IsValid = false;
      message = "Please check Agreement";
    }
    else if (!this.FoundUsOnSelected) {
      IsValid = false;
      this.NotSelectFUO = true;
      message = "Please select Found Us On";
    }
    //else if (!this.IsLimitedOptionSelected) {
    //  if (this.checkout.franchise.hasLimitedDamage) {
    //    try {
    //      this.renderer.invokeElementMethod(this.deliveryInfoDiv.nativeElement, 'focus');
    //    } catch (e) {

    //    }

    //    IsValid = false;
    //    message = "Please Accept/Decline Limited Wavier in Set 2";
    //  }
    //}
    //else if (!this.IsSigned) {
    //  IsValid = false;
    //  message = "Please Sign Contract";
    //  //(this.signatureSlotDiv.nativeElement as HTMLInputElement).focus();
    //}


    if (!IsValid) {

      let action = "Ok";
      this._snackBar.open(message, action, {
        duration: 10000, horizontalPosition: "center", verticalPosition: "top"
      });
    }

    return IsValid;
  }

  placeOrder() {

    try {
      this.updateAddress();
    } catch (e) {

    }


    localStorage.setItem('deliveryAddress', this.deliveryAddress);
    localStorage.setItem('notes', this.notes);
    localStorage.setItem('postalCode', this.postalCode);
    localStorage.setItem('siteContact', this.siteContact);
    localStorage.setItem('unitNo', this.unitNo);

    this.ngxUiLoaderService.start();
    this.dataService.placeOrder(this.customerId)
      .subscribe(
        (data: responseMsg) => {
          try {
            localStorage.removeItem('notes');
          } catch (e) {

          }
          if (data.status == 200) {
            localStorage.setItem('checkout', JSON.stringify(this.checkout));

            this.router.navigate(["/orderConfirmation"]);
            this.ngxUiLoaderService.stop();
          }
          else {
            let message = data.msg;
            //let action = "Ok";
            //this._snackBar.open(message, action, {
            //  duration: 10000, horizontalPosition: "center", verticalPosition: "top"
            //});
            this.ngxUiLoaderService.stop();

            alert(message);

            if (data.msg == "Please enter 6 digit postal code in address" || data.msg == "Sorry we no longer serve at the postalcode you entered, Please change postal code.") {
              this.editDeliveryAddress();
            }
          }
          //this.ngxUiLoaderService.stop();
        },
        (err: any) => {
          this.ngxUiLoaderService.stop();
        },
        () => {
          //this.ngxUiLoaderService.stop();
          console.log("");
        }
      );
  }

  selectProduct(item: IRentalProducts): void {

    let franchiseName = localStorage.getItem('currentCity');

    let prodName = '';
    try {
      prodName = item.shortName.replace(/\s/g, '').trim();
    } catch (e) {

    }

    if (franchiseName == null || franchiseName == undefined) {
      franchiseName = 'rentalProduct';
    }

    if (item.isStandardRental) {
      localStorage.setItem("productId", item.productId.toString());
      this.router.navigate(["/standardRental", franchiseName, prodName]);
    } else {
      if (franchiseName == null || franchiseName == undefined) {
        franchiseName = 'rentalProduct';
      }

      //if (item.isWeeklyProduct == null) {
      //  item.isWeeklyProduct = false;
      //}

      //if (item.isWeeklyProduct) {
      //  localStorage.setItem("productIdWeekly", item.productId.toString());
      //  this.router.navigate(["/fourWeekProduct", prodName, item.productId]);
      //} else {
      localStorage.setItem("productId", item.productId.toString());
      this.router.navigate(["/productdetails", franchiseName, prodName]);
      //}
    }
  }

  selectKProduct(item: IKitProducts) {
    localStorage.setItem("kproductId", item.packageId.toString());

    let prodName = item.packageName.replace(/\s/g, '').trim();

    this.router.navigate(["/kit", prodName, item.packageId]);
  }

  selectSProduct(item: IStandardProducts) {
    localStorage.setItem("sproductId", item.productId.toString());

    let prodName = item.name.replace(/\s/g, '').trim();

    this.router.navigate(["/standard", prodName, item.productId]);
  }

  placeOrderSignDialog() {

    let validated = this.validation();
    // if(this.showDeliveryAddress != 'true')
    // {
    //   alert("Please select a delivery Address");
    // }
    if (validated) {
      if (!this.isSigned) {
        const dialogRef = this.dialog.open(SignDialogComponent, {
          height: '98%',
          width: '98%',
          disableClose: true,
          data: {
            franchiseId: this.franchiseId,
            orderId: this.checkout.data[0].orderId,
            checkout: this.checkout,
            company: this.company,
            franchise: this.checkout.franchise,
            limitedDamage: this.IsLimitedAccepetd
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.isSigned = true;
            this.placeOrder();
          }
        });
      }
      else {
        this.placeOrder();
      }
    }
  }

  NotSelectFUO = false;

  foundUsOnSelected(event: any) {
    this.NotSelectFUO = false;
    this.FoundUsOnSelected = true;
    console.log(event);
    this.dataService.foundUsOnCheckout(this.customerId, event)
      .subscribe(
        (data: void) => {
        },
        (err: any) => {
        },
        () => {
          console.log("");
        }
      );
  }

  AcceptLDW() {
    this.IsLimitedAccepetd = true;

    this.dataService.setLimitedDamage(this.IsLimitedAccepetd, this.customerId)
      .subscribe(
        (data: void) => {

          let message = "";

          if (this.IsLimitedAccepetd) {
            message = "Limited Damage Waiver Accepted";
            this.accept = "Accepted";
            this.decline = "Decline";
          }
          else {
            message = "Limited Damage Waiver Declined";
            this.accept = "Accept";
            this.decline = "Declined";
          }

          this.calculateCost();

          let action = "Ok";
          this._snackBar.open(message, action, {
            duration: 10000, horizontalPosition: "center", verticalPosition: "top"
          });
        },
        (err: any) => {
        },
        () => {
          console.log("set Limited Damage Waiver");
        }
      );
  }

  dialogAsked = false;

  DeclineLDWButton() {
    try {
      //if (!this.dialogAsked) {

      this.dialogAsked = true;

      const dialogRef = this.dialog.open(DeclineLWDDialogComponent, {
        height: this.screenWidth < 500 ? '450px' : '260px',
        width: this.screenWidth < 500 ? '95vw' : '80vw',
        disableClose: true,
        data:
        {
          customerId: this.customerId,
          franchiseId: this.franchiseId,
          addressId: this.checkout.deliveryAddress.addressId
        }
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          this.AcceptLDW();
        }
        else {
          this.DeclineLDW();
        }
      });
      //}
    } catch (e) {

    }
  }

  DeclineLDW() {
    this.IsLimitedAccepetd = false;

    this.dataService.setLimitedDamage(this.IsLimitedAccepetd, this.customerId)
      .subscribe(
        (data: void) => {

          let message = "";

          if (this.IsLimitedAccepetd) {
            message = "Limited Damage Waiver Accepted";
            this.accept = "Accepted";
            this.decline = "Decline";
          }
          else {
            message = "Limited Damage Waiver Declined";
            this.accept = "Accept";
            this.decline = "Declined";
          }

          this.calculateCost();

          let action = "Ok";
          this._snackBar.open(message, action, {
            duration: 10000, horizontalPosition: "center", verticalPosition: "top"
          });



        },
        (err: any) => {
        },
        () => {
          console.log("set Limited Damage Waiver");
        }
      );
  }

}
